// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-advisors-js": () => import("./../../../src/pages/advisors.js" /* webpackChunkName: "component---src-pages-advisors-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-design-workshop-js": () => import("./../../../src/pages/design-workshop.js" /* webpackChunkName: "component---src-pages-design-workshop-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-markus-in-ghana-js": () => import("./../../../src/pages/markus-in-ghana.js" /* webpackChunkName: "component---src-pages-markus-in-ghana-js" */),
  "component---src-pages-news-focus-js": () => import("./../../../src/pages/news-focus.js" /* webpackChunkName: "component---src-pages-news-focus-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-our-concept-js": () => import("./../../../src/pages/our-concept.js" /* webpackChunkName: "component---src-pages-our-concept-js" */),
  "component---src-pages-our-partners-js": () => import("./../../../src/pages/our-partners.js" /* webpackChunkName: "component---src-pages-our-partners-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-story-js": () => import("./../../../src/pages/story.js" /* webpackChunkName: "component---src-pages-story-js" */),
  "component---src-pages-strategy-js": () => import("./../../../src/pages/strategy.js" /* webpackChunkName: "component---src-pages-strategy-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-partner-page-js": () => import("./../../../src/templates/partner-page.js" /* webpackChunkName: "component---src-templates-partner-page-js" */)
}

